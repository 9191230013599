import React from "react";
import "./_scroll-and-trigger.scss"
import PropTypes from "prop-types";
import {isMobile} from "../../utils/MobileUtils";

class ScrollAndTrigger extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            triggered: false,
            timeStamp: 0
        };
        if (process.env.NODE_ENV === "test") {
            this.area = React.createRef();
            this.inside = React.createRef();
        }
    }

    componentDidMount() {
        this.area.addEventListener(isMobile() ? "touchmove" : "scroll", this.handleScroll);
    }

    componentWillUnmount() {
        this.area.removeEventListener(isMobile() ? "touchmove" : "scroll", this.handleScroll);
    }

    handleScroll = (e) => {
        const {onTriggered} = this.props;
        if (!this.state.triggered) {
            this.setState({
                timeStamp: e.timeStamp
            });
            try {
                let height = this.inside.getClientRects()[0].height;
                let scrolled = Math.abs(this.inside.getClientRects()[0].top - this.area.getClientRects()[0].height - this.area.getClientRects()[0].y);
                console.log(height, scrolled)
                if (Math.floor(height) <= Math.floor(scrolled)) {
                    this.setState({
                        triggered: true,
                    }, ()=>{
                        onTriggered(e.timeStamp);
                    })
                }
            } catch (e) {

            }
        }

    };


    render() {
        const {style, children} = this.props;
        const {timeStamp} = this.state;
        return (
            <div timestamp={timeStamp} className="scroll-and-trigger" style={style} ref={ref => this.area = ref}>
                <div className={"scroll-and-trigger-content"} ref={ref => this.inside = ref}>
                    {children}
                </div>
            </div>
        )
    }

}

ScrollAndTrigger.propTypes = {
    onTriggered: PropTypes.func
};

ScrollAndTrigger.defaultProps = {
    onTriggered: () => {
    },
};

export default ScrollAndTrigger