import React from "react";
import PropTypes from "prop-types"
import {isMobile} from "../../utils/MobileUtils";
import ReduxLabeledSelect from "../basics/select/labeled-select/ReduxLabeledSelect";
import ReduxLabeledSlider from "../basics/slider/labeled-slider/ReduxLabeledSlider";
import _ from "lodash";

export default class ReduxLabeledSliderToSelect extends React.Component {


    generateOptions = ({min, max, step, valueFormatter}) => {
        const options = [];
        for (let i = min; i <= max; i += step) {
            options.push({
                name: valueFormatter(i),
                value: i
            })
        }
        return options;
    };

    render() {
        const {mobile: mobileProps} = this.props;
        const mobile = _.isNil(mobileProps) ? isMobile() : !!mobileProps;
        if (mobile) {
            const options = this.generateOptions(this.props);
            return (
                <ReduxLabeledSelect {...this.props} options={options}/>

            )
        }
        return (
            <ReduxLabeledSlider {...this.props}/>

        )


    }

}

ReduxLabeledSliderToSelect.propTypes = {
    id: PropTypes.string,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number,
    input: PropTypes.shape({
        value: PropTypes.number.isRequired,
        onChange: PropTypes.number.isRequired
    }),
    isRange: PropTypes.bool,
    disabled: PropTypes.bool,
    valueFormatter: PropTypes.func,
    tipFormatter: PropTypes.func
};

ReduxLabeledSliderToSelect.defaultProps = {
    disabled: false,
    step: 1,
    min: 0,
    max: 100,
    meta: {},
    input: {},
    valueFormatter: (value) => value

};