import React from "react";
import SwatchesPicker from "react-color/lib/components/swatches/Swatches";
import Button from "../basics/button/Button";
import "./_color-picker.scss"
import ReactDOM from "react-dom";

export class ColorPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showPicker: false
        }
    }

    onChange(value) {
        this.props.input.onChange(value.hex);
        this.setState({
            showPicker: false
        })
    }

    render() {
        console.log(this.props);
        const {meta: {error, touched}} = this.props;
        return (
            <div className="color-picker" ref={ref => this.dev = ref} data-tip={touched ? error : ""}>
                <Button type={"button"} className="color-picker-button"
                        style={{backgroundColor: this.props.input.value}}
                        onClick={() => this.setState(state => ({showPicker: !state.showPicker}))}>&nbsp;</Button>
                {
                    this.state.showPicker &&
                    ReactDOM.createPortal(<div className="color-picker-window" style={{
                        top: this.dev.getBoundingClientRect().top + 15 + window.scrollY,
                        left: this.dev.getBoundingClientRect().left + 15
                    }}>
                        <SwatchesPicker
                            {...this.props}
                            {...this.props.input}
                            color={this.props.input.value}
                            onChange={value => this.onChange(value)}
                        />
                    </div>, document.getElementById("root") || document.createElement('div'))
                }
            </div>
        )
    }

}

export default ColorPicker;