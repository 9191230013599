import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {isMobile} from "../../utils/MobileUtils";
import {LicPackageKeys, LicPackageLevel, Roles} from "../../constans/roles";
import SideBar from "../basics/side-bar/SideBar";
import {withTranslation} from "react-i18next";
import {getUnreadCounter} from "../../selectors/notificationSelector";

//Klasa od tworzenia lewego paska
@connect((store) => {
    return {
        farm: store.location.farm,
        farms: store.farms.farms,
        unreadNotification: getUnreadCounter(store),
        fetchedNotifications: store.notificationCenter.fetched,
    };
})
export class LeftSideBar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mute: true
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        // todo: fix sound on initial site visit (what are those reducers smh)
        if (this.props.fetchedNotifications !== nextProps.fetchedNotifications) {
            if (!this.timer) {
                this.timer = setTimeout(() => {
                    this.setState({
                        mute: false
                    })
                }, 500)
            }
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        const {unreadNotification, t} = this.props;
        const frm = this.props.farms.filter(f => {
            return f.FarmID === this.props.farm;
        })[0];

        const generalMenuItems = [
            {
                iconClass: "fas fa-tasks",
                icon: "fas fa-tasks",
                locations: ["tasks"],
                href: `/${this.props.farm}/farm/general`,
                name: t("tasks"),
                disRedirect: true,
                isShowing: "general",
                roles: [Roles._GENERAL_READ],
                packages: {[LicPackageKeys.ALARM]: LicPackageLevel.EXTENDED}
            }
        ]

        const devicesMenuItems = [
            {
                iconClass: "fas fa-siren-on",
                icon: "fas fa-siren-on",
                locations: ["alarms"],
                href: `/${this.props.farm}/alarms`,
                name: t("alarms"),
                disRedirect: true,
                isShowing: "alarms",
                roles: [Roles._GENERAL_READ],
                packages: {[LicPackageKeys.ALARM]: LicPackageLevel.EXTENDED}
            },
            {
                icon: "fas fa-utensils",
                locations: ["feeding"],
                href: `/${this.props.farm}/farm/feeding`,
                name: t("feeding"),
                disRedirect: true,
                isShowing: "feeding",
                roles: [Roles._GENERAL_READ],
                packages: {[LicPackageKeys.DISPENSER]: LicPackageLevel.BASIC}
            },
            {
                icon: "fas fa-thermometer-half",
                locations: ["climate"],
                href: `/${this.props.farm}/farm/climate`,
                name: t("climate"),
                disRedirect: true,
                isShowing: "climate",
                roles: [Roles._GENERAL_READ],
                packages: {[LicPackageKeys.CLIMATE]: LicPackageLevel.BASIC}
            },
            {
                icon: "fas fa-weight",
                locations: ["weights"],
                href: `/${this.props.farm}/farm/weights`,
                name: t("weight"),
                disRedirect: true,
                isShowing: "silo",
                roles: [Roles._GENERAL_READ],
                packages: {[LicPackageKeys.SMALL_CAGE]: LicPackageLevel.BASIC}
            },
            {
                icon: "fas fa-farm",
                locations: ["silo"],
                href: `/${this.props.farm}/farm/silo`,
                name: t("siloses"),
                disRedirect: true,
                isShowing: "silo",
                roles: [Roles._GENERAL_READ],
                packages: {[LicPackageKeys.SILO]: LicPackageLevel.BASIC}
            },
            {
                icon: "fas fa-door-open",
                locations: ["cage"],
                href: `/${this.props.farm}/farm/cage`,
                name: t("cage"),
                disRedirect: true,
                isShowing: "cage",
                roles: [Roles._GENERAL_READ],
                packages: {[LicPackageKeys.CAGE]: LicPackageLevel.BASIC}
            },

            {
                icon: "fas fa-faucet",
                locations: ["water"],
                href: `/${this.props.farm}/farm/water`,
                name: t("water"),
                disRedirect: true,
                isShowing: "water",
                roles: [Roles._GENERAL_READ],
                packages: {[LicPackageKeys.FARM]: LicPackageLevel.BASIC}
            },
            {
                icon: "fas fa-bolt",
                locations: ["electricity"],
                href: `/${this.props.farm}/farm/electricity`,
                name: t("electricity"),
                disRedirect: true,
                isShowing: "electricity",
                roles: [Roles._GENERAL_READ],
                packages: {[LicPackageKeys.FARM]: LicPackageLevel.BASIC}
            },
            {
                icon: "fas fa-link",
                locations: ["chainFeeding"],
                href: `/${this.props.farm}/farm/chainFeeding`,
                name: t("chainFeeding"),
                disRedirect: true,
                isShowing: "chainFeeding",
                roles: [Roles._GENERAL_READ],
                packages: {[LicPackageKeys.FARM]: LicPackageLevel.BASIC}
            },
            {
                icon: "fas fa-map",
                locations: ["map"],
                href: `/${this.props.farm}/farm/map`,
                name: t("map"),
                disRedirect: true,
                isShowing: "map",
                roles: [Roles._GENERAL_READ],
                packages: {[LicPackageKeys.FARM]: LicPackageLevel.BASIC}
            }
        ];

        const breedingMenuItems = [
            {
                icon: "fas fa-warehouse",
                locations: ["inventory"],
                href: `/${this.props.farm}/farm/inventory`,
                name: t("inventory"),
                disRedirect: true,
                isShowing: "inventory",
                roles: [Roles._GENERAL_READ]
            },
            {
                icon: "fas fa-pig",
                locations: ["eventChooser"],
                href: `/${this.props.farm}/mobile`,
                name: t("setEvent"),
                disRedirect: true,
                isShowing: "eventChooser",
                roles: [Roles._BREEDING]
            },
            {
                icon: "fas fa-sack-dollar",
                locations: ["sell"],
                href: `/${this.props.farm}/sell`,
                name: t("sale"),
                isShowing: "technolgyGroups",
                roles: [Roles._BREEDING]
            },
            {
                icon: "fas fa-list-alt",
                locations: ["controlLists"],
                href: `/${this.props.farm}/controlLists/all`,
                name: t("controlLists.mainView.header"),
                isShowing: "controlLists",
                roles: [Roles._BREEDING]
            }
        ];

        const bottomMenuItems = [
            {
                icon: "fas fa-cog",
                locations: ["settings"],
                href: `/${this.props.farm}/settings`,
                name: t("settings.settings"),
                isShowing: "area",
                roles: [Roles._GENERAL_READ],
            },
            {
                icon: "fas fa-bell",
                locations: ["notificationCenter"],
                href: `/${this.props.farm}/notificationCenter`,
                name: t("notifications.notifications"),
                isShowing: "area",
                roles: [Roles._GENERAL_READ],
                counter: unreadNotification
            },
            {
                icon: "fas fa-info",
                locations: ["help"],
                href: '/help',
                name: t("help"),
                isShowing: "area",
                roles: [Roles._GENERAL_READ]
            },
            {
                icon: "fas fa-history",
                locations: ["logs"],
                href: '/logs',
                name: t("logs"),
                isShowing: "logs",
                roles: [Roles._SERVICE],
            }
        ];


        const menuItems = [
            {
                iconClass: "fas fa-home",
                icon: "fas fa-home",
                locations: ["map", "farms", "sectors", "farm", "sector", "chamber"],
                href: `/${this.props.farm}/farm`,
                name: frm ? frm.FarmName : t("farm"),
                disRedirect: true,
                isShowing: "farm",
                roles: [Roles._GENERAL_READ],
                hide: isMobile()
            },
            {
                icon: "fas fa-pig",
                locations: ["eventChooser"],
                href: `/${this.props.farm}/eventChooser`,
                name: t("setEvent"),
                disRedirect: true,
                isShowing: "eventChooser",
                roles: [Roles._BREEDING]
            },
            {
                icon: "fas fa-chart-line",
                locations: ["reports"],
                href: `/${this.props.farm}/reports`,
                name: t("reports"),
                isShowing: "reports",
                roles: [Roles._REPORT_READ],
                hide: isMobile()
            },
            {
                icon: "fas fa-sack-dollar",
                locations: ["sell"],
                href: `/${this.props.farm}/sell`,
                name: t("sale"),
                isShowing: "technolgyGroups",
                roles: [Roles._BREEDING]
            },
            {
                icon: "fas fa-list-alt",
                locations: ["controlLists"],
                href: `/${this.props.farm}/controlLists/all`,
                name: t("controlLists.mainView.header"),
                isShowing: "controlLists",
                roles: [Roles._BREEDING]
            },
            {
                icon: "fas fa-cog",
                locations: ["settings"],
                href: `/${this.props.farm}/settings`,
                name: t("settings.settings"),
                isShowing: "area",
                roles: [Roles._GENERAL_READ],
            },
            {
                icon: "fas fa-bell",
                locations: ["notificationCenter"],
                href: `/${this.props.farm}/notificationCenter`,
                name: t("notifications.notifications"),
                isShowing: "area",
                roles: [Roles._GENERAL_READ],
                counter: unreadNotification ? `${unreadNotification > 100 ? `+99` : unreadNotification}` : ''
            },
            {
                icon: "fas fa-info",
                locations: ["help"],
                href: '/help',
                name: t("help"),
                isShowing: "area",
                roles: [Roles._GENERAL_READ]
            },
            {
                icon: "fas fa-history",
                locations: ["logs"],
                href: '/logs',
                name: t("logs"),
                isShowing: "logs",
                roles: [Roles._SERVICE],
            },
            {
                icon: "fas fa-siren-on",
                locations: ["alarms"],
                href: `/${this.props.farm}/alarms`,
                name: t("alarms"),
                isShowing: "alarms",
                roles: [Roles._GENERAL_READ],
                hide: !isMobile()
            },
        ];

        let showBadge = !!unreadNotification;
        return <SideBar devicesMenuItems={devicesMenuItems} breedingMenuItems={breedingMenuItems}
                        bottomMenuItems={bottomMenuItems} menuItems={menuItems} showBadge={showBadge}
                        generalMenuItems={generalMenuItems}/>
    }
}

export const _LeftSideBar = LeftSideBar;
LeftSideBar = withRouter(LeftSideBar);

export default withTranslation()(LeftSideBar);
