import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./_right-drawer.scss";
import {Col} from "react-bootstrap";
import {bodyLockScroll, bodyUnlockScroll, ScrollLock} from "../../utils/DOMUtils";
import {isMobile} from "../../utils/MobileUtils";
import windowSize from "react-window-size";

class RightDrawer extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidMount() {
        this.handleStateChange();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {opened, windowWidth, windowHeight} = this.props;
        if ((prevProps.opened !== opened) || (prevProps.windowWidth !== windowWidth) || (prevProps.windowHeight !== windowHeight)) {
            this.handleStateChange();
        }
    }

    handleStateChange = () => {
        try {
            console.log(this.ref)
            const {opened} = this.props;
            const {current} = this.ref;
            // current.style.right = opened ? `0` : `-${(current.getBoundingClientRect().width - current.getElementsByClassName("buttons")[0].getBoundingClientRect().width)}px`;
            opened ? bodyLockScroll(ScrollLock.RIGHT_DRAWER) : bodyUnlockScroll(ScrollLock.RIGHT_DRAWER);
            current.style.right = opened ? `0` : `-${(current.getBoundingClientRect().width)}px`;
        } catch (err) {
            console.error(err);
        }

    }

    componentWillUnmount() {
        bodyUnlockScroll(ScrollLock.RIGHT_DRAWER);
    }

    render() {
        const {icon, iconId, onToggle, children, header} = this.props;
        const className = `fetura-right-drawer${isMobile() ? " mobile" : ""}`;
        return (
            <div ref={this.ref} className={className}>
                <div className={"buttons"}>
                    <i id={iconId} className={icon} onClick={onToggle}/>
                </div>
                <Col xs={12} className={"data"}>
                    <h2 className={"d-flex align-items-center"}>
                        <i onClick={onToggle} className={"fas pointer fa-times fa-fw"}/>
                        {header}
                    </h2>
                    <br/>
                    {children}
                    <br/>
                </Col>
            </div>
        );
    }
}

RightDrawer.propTypes = {
    icon: PropTypes.object.isRequired,
    iconId: PropTypes.string,
    onToggle: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    opened: PropTypes.bool.isRequired
};

export default windowSize(RightDrawer);
