import React from "react";
import TableGrid from "../basics/table-grid/TableGrid";
import BillingCardType from "../../views/farm-settings-view/billing/BillingCardType";
import BillingPaymentButtons from "../../views/farm-settings-view/billing/BillingPaymentButtons";
import {connect} from "react-redux";
import MobilePaymentMethod from "./MobilePaymentMethod";
import "./_payment-methods.scss";
import {withTranslation} from "react-i18next";
import {isEqual} from "lodash";

export class PaymentMethods extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            paymentMethods: PaymentMethods.getData(this.props)
        }
    }

    static getData(props) {
        return props.paymentMethods;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {paymentMethods, creditCards} = this.props;
        if (!isEqual(prevProps.paymentMethods, paymentMethods) || !isEqual(prevProps.creditCards, creditCards)) {
            this.setState({
                paymentMethods: PaymentMethods.getData(this.props)
            })
        }
    }

    maskedNumberValueFormatter = value => {
        return `${value.substr(0, 4)} ${value.substr(4, 4)} ${value.substr(8, 4)} ${value.substr(12, 4)}`
    };

    render() {
        const {t} = this.props;
        const {paymentMethods} = this.state;
        const paymentHeaders = [
            {
                name: "",
                field: "imageUrl",
                component: <BillingCardType/>,
                headerClassName: "payment-method-image hide-on-small-device md",
                itemClassName: "payment-method-image hide-on-small-device md"
            },
            {
                name: t("cardNumber"),
                field: "maskedNumber",
                valueFormatter: this.maskedNumberValueFormatter
            },
            {
                name: t("owner"),
                field: "data.cardholderName"
            },
            {
                name: t("farmSettings.paymentMethods.expirationDate"),
                field: "expirationDate"
            },
            {
                name: t("farmSettings.paymentMethods.producer"),
                field: "cardType"
            },
            {
                name: "",
                component: <BillingPaymentButtons/>,
                headerClassName: "payment-method-buttons",
                itemClassName: "payment-method-buttons"
            }
        ];
        return (
            <TableGrid data={paymentMethods.sort((a, b) => b.data.default - a.data.default)}
                       headers={paymentHeaders} mobileRow={<MobilePaymentMethod/>}/>
        );
    }

}

PaymentMethods = connect(state => ({
    paymentMethods: state.braintree.paymentMethodList,
}))(PaymentMethods);

export default withTranslation()(PaymentMethods);
