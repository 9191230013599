import React from "react";
import PropTypes from "prop-types";
import Button from "../basics/button/Button";
import BillingPaymentButtons from "../../views/farm-settings-view/billing/BillingPaymentButtons";

export class MobilePaymentMethod extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            event: null
        }
    }

    maskedNumberValueFormatter = value => {
        return `${value.substr(0, 4)} ${value.substr(4, 4)} ${value.substr(8, 4)} ${value.substr(12, 4)}`
    };

    onClick = event => {
        this.setState({
            event
        })
    };

    render() {
        const {object} = this.props;
        const {event} = this.state;
        return (
            <div className="mobile-payment-method">
                <div className="mobile-payment-method-data">
                    <img src={object.imageUrl} alt={object.cardType}/>
                    <div>
                        <div className={object.data.default ? "font-weight-bold" : ""}>
                            {this.maskedNumberValueFormatter(object.maskedNumber)}
                        </div>
                        <div>
                            <small>{object.data.cardholderName} ({object.expirationDate})&nbsp;</small>
                        </div>
                    </div>
                </div>
                <Button buttonStyle={"text"} icon={<i className="fas fa-ellipsis-v"/>} onClick={this.onClick}/>
                <BillingPaymentButtons showAsMenu event={event} value={object}/>
            </div>
        );
    }

}

MobilePaymentMethod.propTypes = {
    object: PropTypes.object.isRequired
};

export default MobilePaymentMethod;
