import React, {Component} from 'react';
import {scrollToTop} from "../../utils/DOMUtils";
import Button from "../basics/button/Button";
import ButtonGroup from "../basics/button/button-group/ButtonGroup";
import _ from "lodash";
import PropTypes from "prop-types"

class ScrollTopButton extends Component {

    constructor(props) {
        super(props);
        this.handleScroll = _.debounce(this.handleScroll.bind(this), 150);
        this.state = {
            externalScroll: !!props.externalScroll,
            scrollTop: props.externalScroll ? props.scrollTop || 0 : ScrollTopButton.getScroll(document)
        }
    }

    static getScroll = ({scrollingElement: {scrollTop = 0} = {}} = {}) => {
        return scrollTop;
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.externalScroll) {
            if (nextProps.scrollTop !== prevState.scrollTop) {
                return {
                    scrollTop: nextProps.scrollTop
                }
            }
        }
        return null;
    }

    handleScroll() {
        this.setState({
            scrollTop: ScrollTopButton.getScroll(document)
        })
    }

    componentDidMount() {
        if (!this.state.externalScroll) {
            window.addEventListener("scroll", this.handleScroll);
        }
    }

    componentWillUnmount() {
        if (!this.state.externalScroll) {
            window.removeEventListener("scroll", this.handleScroll);
        }
    }

    show = () => {
        const {show} = this.props;
        const {scrollTop} = this.state;
        if (_.isFunction(show)) {
            return !!show({scrollTop})
        } else {
            return !!show;
        }
    };

    render() {
        const show = !!this.show();
        return (

            <ButtonGroup show={show} fixed renderInPortal={true}>
                <Button type={"button"} onClick={scrollToTop} buttonColor={"info"}
                        buttonStyle={"round"}
                        icon={<i className="fas fa-arrow-to-top"/>}/>
            </ButtonGroup>
        );
    }
}

ScrollTopButton.defaultProps = {
    show: ({scrollTop}) => scrollTop > 100,
    scrollTop: 0,
    externalScroll: false
};
ScrollTopButton.propTypes = {
    show: PropTypes.func,
    externalScroll: PropTypes.bool,
    scrollTop: PropTypes.number
};
export default ScrollTopButton;