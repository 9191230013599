import React from "react";
import isOnline from "is-online";
import PropTypes from "prop-types";


export class Offline extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            online: true,
            interval: null
        }
    }

    async registerSync() {
        try {
            let registration = await navigator.serviceWorker.ready;
            await registration.sync.register("fetura-sync");
        } catch (e) {
            console.error("Error while registering background sync", e);
        }
    }

    async componentDidMount() {
        const {onOnlineChange} = this.props;
        let online = await isOnline();
        onOnlineChange && onOnlineChange(online);
        if (!online) {
            this.registerSync();
        } else {
            localStorage.removeItem("offlineLogin");
            localStorage.removeItem("offlinePass");
        }
        this.setState({
            online
        }, () => {
            let interval = setInterval(async () => {
                let online = await isOnline();
                onOnlineChange && onOnlineChange(online);
                if (!online) {
                    this.registerSync();
                } else {
                    localStorage.removeItem("offlineLogin");
                    localStorage.removeItem("offlinePass");
                }
                this.setState({
                    online
                });
            }, 10000)
            this.setState({
                interval
            })
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    render() {
        if (this.state.online) return null;
        return (
            <div className={"no-connection offline warning"}>
                <i className="fa fa-wifi-slash"/>
            </div>
        );
    }

}

Offline.propTypes = {
    onOnlineChange: PropTypes.func
}

export default Offline;